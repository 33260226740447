import React, { useState, useEffect } from 'react';
import LoadSpinner from '../components/LoadSpinner';
import Message from '../components/Message';
import SelectItem from '../components/SelectItem';
import ShopByCategory from '../components/ShopByCategory';
import '../css/HomeScreen.css';
import 'swiper/swiper-bundle.min.css';
import { listProducts } from '../actions/productActions';
import { Link } from 'react-router-dom';
import Slider from '../components/slider';
import CartSidebar from '../components/CartSidebar';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import ProductList from '../components/ProductList';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';

SwiperCore.use([Navigation, Autoplay]);

function HomeScreen({ history }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const messageInfo = useSelector((state) => state.message);
  const sidebarShow = useSelector((state) => state.cart.sidebarShow);

  const { loading, products, products1 } = productList;
  const [nowItem, setNowItem] = useState(null);
  const [show, setShow] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(4);
  let keyword = history.location.search;

  useEffect(() => {
    dispatch(listProducts(keyword));
    resize();
    window.onresize = resize;
  }, [dispatch, keyword]);

  const resize = () => {
    let w = window.innerWidth * 1;
    if (w < 540) {
      setSlidesPerView(1);
    } else if (w < 640) {
      setSlidesPerView(2);
    } else if (w < 935) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(4);
    }
  };

  const selectAddCart = (item, index) => {
    return () => {
      setNowItem(item);
      setShow(true);
    };
  };

  const noFun = () => {
    setNowItem({});
    setShow(false);
  };

  return (
    <div className="home">
      {show ? <SelectItem item={nowItem} noFun={noFun}/> : ''}
      {messageInfo.msg ? <Message variant={messageInfo.variant}>{messageInfo.msg}</Message> : ''}
      {loading ? <div className="fullcreen"><LoadSpinner/></div> : messageInfo.msg ? '' :
        <div>
          <div className="maxWidth">
            <Slider />
            <div style={{ marginBottom: '20px' }}>
                <ShopByCategory />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default HomeScreen;
