import React from 'react'
import { Link } from 'react-router-dom'
import '../css/AboutScreen.css'

function About({ history }) {

    return (
        <div className="about">
           <div className="bgAbout">
				<div className="top maxWidth">
					<img src="./images/index/aroma1.png" className="aboutCar" />
					<div className="aboutCont">
						<div className="topName">About Us</div>
						<div>
						Aroma Roads, a leading supplier of car fragrance products, is deeply committed to customer care and enhancing the shopping experience. Our continuous dedication to research and development ensures that we consistently deliver the best value to our customers.
						</div>
					</div>
					<div className="aboutCarTwo">
						<img src="./images/index/car1.png"   />
					</div>
				</div>
			</div>
			<div className="maxWidth">
				<div className="secction flex">
					<div className="secctItem mr1">
						Our team will be happy to advise on our products.
					</div>
					<div className="secctItem">
						For information call us on 02089 989547 to speak to one of our dedicated team.
					</div>
				</div>
				<div>
					<div className="topTitle"> </div>
					<div className="topCont">

					</div>
				</div>
			</div>
			<div className="operate">
				<div className="flex secctBtn maxWidth">
					<div className="title">Check Out Our Products</div>
					<div className="btns"><Link to="/">Shop Now</Link></div>
				</div>
			</div>
        </div >
    )
}

export default About