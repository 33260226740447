import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import Message from '../components/Message'
// import Loader from '../components/Loader'
import LoadSpinner from '../components/LoadSpinner'
import { getOrderDetails, payOrder, deliverOrder, youkeOrder } from '../actions/orderActions'
import { CART_CLEAR_ITEMS } from '../constants/cartConstants'
function OrderTouristScreen({ match, history }) {
    
    const dispatch = useDispatch()

    const [isPay, setIsPay] = useState(false)
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const [sdkReady, setSdkReady] = useState(false)
    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart
    const orderInfo = useSelector(state => state.order)
    let hazard = 1
    const itemsPrice = cart.itemsList.reduce((acc, item) => {
        if(+item.hazard === 0 ) {
            hazard = 0
        }
        return acc + (typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price) * item.qty * (item.discount || 1)
    }, 0).toFixed(2)
    let flag = (userInfo && +userInfo.menkan100 === 0) || !userInfo
    let flag2 = (userInfo && +userInfo.menkan300 === 0) || !userInfo
    const yunfei = hazard === 1 ? 
                 ( flag && cart.itemsPrice < 100  ? 10 : 0) : 
                 ( flag2 && cart.itemsPrice < 600 ? 50 : 0)
    // cart.yunfei = (itemsPrice > 10 ? 0 : 1.5).toFixed(2)
    //const yunfei = 0;
    const Shui = Number(itemsPrice - (itemsPrice / 1.2)).toFixed(2)

    const totalPrice = (Number(itemsPrice) + Number(yunfei)).toFixed(2)
    var obj = JSON.parse(JSON.stringify(cart))
    const [cartCopy] = useState({
        ...JSON.parse(JSON.stringify(cart)),
        itemsPrice,
        totalPrice,
        yunfei,
        Shui
    })
    // console.log(cartCopy, 999);
    const messageInfo = useSelector(state => state.message)

  

    const youkePay = () => {
        let get_way = match.url === '/zitiquhuo/tourist' ? '自提取货' : '送货上门'
        let param = {
            get_way: get_way,
            TotalPrices: itemsPrice,
            Yunfei: yunfei,
            Amount: totalPrice,
            Shui: Shui,
            Items: cart.cartItems,
            ItemsJson: cart.itemsList,
            receiver: '',
            phone: '',
            email: shippingAddress.email,
            company: '',
            nation: '',
            province: '',
            city: '',
            postcode: '',
            addinfo: ''
        }
        if (match.url !== '/zitiquhuo/tourist') {
            param = {
                ...param,
                receiver: shippingAddress.uname,
                phone: shippingAddress.phone,
                // email: shippingAddress.email,
                company: shippingAddress.gs,
                nation: shippingAddress.country,
                province: shippingAddress.county,
                city: shippingAddress.city,
                postcode: shippingAddress.postcode,
                addinfo: shippingAddress.address
            }
        }
        dispatch(youkeOrder(param, (data) => {
            setIsPay(true)
            dispatch({ type: CART_CLEAR_ITEMS })
        }))

    }


    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
//        script.src = 'https://www.paypal.com/sdk/js?client-id=AffNWpCeJZ9VLsUAWLrEatRj0RcQFiU-urRdNGmdchQl__OdrLclosh0UUkbGq57b2_Ao-b9ay3w30qn&currency=GBP'
        script.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=GBP'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {
        if (!isPay) {
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [dispatch, isPay])


    const successPaymentHandler = (paymentResult) => {
        youkePay();
    }



    return (
        <div className="OrderDetailScreen maxWidth">
            {messageInfo.msg && <Message variant={messageInfo.variant}>{messageInfo.msg}</Message>}

            {orderInfo && orderInfo.loading ? <div className="fullcreen"><LoadSpinner /></div> : <div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="list-group list-group-flush">
                            <div className="list-group-item">
                                <h2 >Shipping</h2>
                                {/* <p><strong>Name: </strong> 329958887@qq.com</p> */}
                                <p><strong>Email: </strong><a href={"mailto:" + shippingAddress.email}>{shippingAddress.email}</a></p>
                                {shippingAddress ? (
                                    match.url === '/zitiquhuo/tourist' ?
                                        <p><strong>Picking Up: </strong> {shippingAddress.dizhi},{shippingAddress.dz1},{shippingAddress.dz2},{shippingAddress.dz3}</p>
                                        :
                                        <p><strong>Shipping: </strong>{shippingAddress.country},  {shippingAddress.county},  {shippingAddress.city},  {shippingAddress.gs},  {shippingAddress.address}</p>
                                ) : <div role="alert" className="fade alert alert-warning show">Not Delivered</div>
                                }


                            </div>
                            <div className="list-group-item">
                                <h2>Payment Method</h2>

                                <p><strong>Method: </strong>PayPal</p>
                                {
                                    !isPay ? <div role="alert" className="fade alert alert-warning show">Not Paid</div> : <div role="alert" className="fade alert alert-success show">Paid Successfully</div>
                                }
                                {/* <div role="alert" className="fade alert alert-warning show">Not Paid</div> */}
                            </div>
                            {/* <div className="list-group-item">
                                <h2>Order Items</h2> */}
                            {
                                cartCopy && cartCopy.itemsList && cartCopy.itemsList.length > 0 ?
                                    (
                                        <div className="list-group-item">
                                            <h2>Order Items</h2>
                                            <div className="list-group list-group-flush">
                                                {
                                                    cartCopy.itemsList.map((item, index) => {
                                                        return <div className="list-group-item">
                                                            <div className="row"><div className="col-md-1">
                                                                <img style={{ width: '100%', height: '100%' }} src={item.image} alt={item.name} className="img-fluid rounded" />
                                                            </div>
                                                                <div className="col">
                                                                    <a href={"#/product/" + item.id}>{item.name}</a>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div>{item.qty} X £{(typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price) * (item.discount || 1) } = £{item.qty * (typeof item.price === 'object'?  item.price[userInfo&&userInfo.name || 'A' ] :item.price) * (item.discount || 1)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>

                                    )
                                    :
                                    <div role="alert" className="fade alert alert-info show">Order is empty</div>
                            }

                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="list-group list-group-flush">
                                <div className="list-group-item"><h2>Order Summary</h2></div>
                                <div className="list-group-item"><div className="row"><div className="col">Subtotal:</div><div className="col">£{cartCopy.itemsPrice}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">Shipping:</div><div className="col">£{cartCopy.yunfei}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">Tax:</div><div className="col">£{cartCopy.Shui}</div></div></div>
                                <div className="list-group-item"><div className="row"><div className="col">Total:</div><div className="col">£{cartCopy.totalPrice}</div></div></div>

                                {!isPay && (
                                    <div className="list-group-item">
                                        {/* {loadingPay && <Loader />} */}

                                        {!sdkReady ? (
                                            <LoadSpinner />
                                        ) : (
                                                <PayPalButton
                                                    amount={totalPrice}
                                                    currency="GBP"
                                                    onSuccess={successPaymentHandler}
                                                />
                                            )}
                                    </div>
                                )}
                                {/* {loadingDeliver && <Loader />} */}
                                {/* {userInfo && userInfo.isAdmin && order.status == 2  && (
                                    <div className="list-group-item">
                                        <button
                                            type='button'
                                            className='btn btn-block'
                                            onClick={deliverHandler}
                                        >
                                            Mark As Delivered
                                    </button>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

        </div>

    )
}

export default OrderTouristScreen
