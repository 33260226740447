import React, { useState, useEffect } from 'react'
import '../css/AddressScreen.css'
import { getAddress, setDefaultAddress, deleteAddress, saveAddress } from '../actions/addressActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import LoadSpinner from '../components/LoadSpinner'
import Loader from '../components/Loader'
import {Button, Col, Form, Input, Row, Select} from "antd";
import {useMediaQuery} from "react-responsive";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const { Option } = Select;

function AddressScreen({ history }) {
	const addInfo = useSelector(state => state.addresses)
	console.log(addInfo)
	const messageInfo = useSelector(state => state.message)
	const [show, setShow] = useState(false);
	const defaultVal = {
		address: '',
		city: '',
		county: '',
		gs: '',
		phone: '',
		postcode: '',
		status: 0,
		uname: '',
	};
	const [addressObj, setAddressObj] = useState(defaultVal);
	// const [isBoxs, setIsBoxs] = useState(false);
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getAddress())
	}, [dispatch])

	const isMobile = useMediaQuery({
		query: '(max-width: 1000px)'
	})

	const setDefaultHandle = (id) => {
		return () => {
			dispatch(setDefaultAddress(id))
		}
	}

	const deleteAddressHandle = (id) => {
		return () => {
			dispatch(deleteAddress(id))
		}
	}
	const showAddress = (item = defaultVal) => {
		return () => {
			setAddressObj(item);
			setShow(true);
		}
	}
	// function changBox(){

	// 	$('.boxCli').hide()
	// 	$('.boxs').show()
	// }
	// const changBox = () => {
	// 	setIsBoxs(true)
	// }
	// const downBox = () => {
	// 	setIsBoxs(false)
	// }
	const closeAddress = () => {
		setAddressObj({});
		setShow(false);
	}
	const saveAddressHanle = () => {
		dispatch(saveAddress(addressObj, closeAddress))
	}
	const changeIptHandle = (key) => {
		return (e) => {
			let obj = { ...addressObj };
			obj[key] = e.target.value;
			// console.log(obj);
			setAddressObj(obj);
		}
	}
	const changeSelectHandle = (key) => {
		return (value) => {
			let obj = { ...addressObj };
			obj[key] = value;
			// console.log(obj);
			setAddressObj(obj);
		}
	}
	console.log(addressObj.county)
	return (
		<div className="AddressScreen right">

			{
				show ? <div className="mask"></div> : ''
			}

			{
				show ? <div className="addressBox">
					<div className="err" onClick={closeAddress}>
						<img src="./images/index/errs.png" />
					</div>
					<div className="account_titleOne">Add an Address</div>

						<Form
							name="basic"
							layout="vertical"
							className="lisOne"
						>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item name="country" label="Country" initialValue={addressObj.country}>
                                        <Select
                                            placeholder="Select Country"
                                            onChange={changeSelectHandle('country')}
                                            value={addressObj.country}
                                        >
                                            <Option value="volvo">Select Country</Option>
                                            <Option value="Albania">Albania</Option>
                                            <Option value="Andorra">Andorra</Option>
                                            <Option value="Armenia">Armenia</Option>
                                            <Option value="Austria">Austria</Option>
                                            <Option value="Azerbaijan">Azerbaijan</Option>
                                            <Option value="Belarus">Belarus</Option>
                                            <Option value="Belgium">Belgium</Option>
                                            <Option value="Bosnia and Herzegovina">Bosnia and Herzegovina</Option>
                                            <Option value="Bulgaria">Bulgaria</Option>
                                            <Option value="Croatia">Croatia</Option>
                                            <Option value="Cyprus">Cyprus</Option>
                                            <Option value="Czech Republic">Czech Republic</Option>
                                            <Option value="Denmark">Denmark</Option>
                                            <Option value="Estonia">Estonia</Option>
                                            <Option value="Finland">Finland</Option>
                                            <Option value="France">France</Option>
                                            <Option value="Georgia">Georgia</Option>
                                            <Option value="Germany">Germany</Option>
                                            <Option value="Greece">Greece</Option>
                                            <Option value="Hungary">Hungary</Option>
                                            <Option value="Iceland">Iceland</Option>
                                            <Option value="Ireland">Ireland</Option>
                                            <Option value="Italy">Italy</Option>
                                            <Option value="Kazakhstan">Kazakhstan</Option>
                                            <Option value="Kosovo">Kosovo</Option>
                                            <Option value="Latvia">Latvia</Option>
                                            <Option value="Liechtenstein">Liechtenstein</Option>
                                            <Option value="Lithuania">Lithuania</Option>
                                            <Option value="Luxembourg">Luxembourg</Option>
                                            <Option value="Malta">Malta</Option>
                                            <Option value="Moldova">Moldova</Option>
                                            <Option value="Monaco">Monaco</Option>
                                            <Option value="Montenegro">Montenegro</Option>
                                            <Option value="Netherlands">Netherlands</Option>
                                            <Option value="North Macedonia">North Macedonia</Option>
                                            <Option value="Norway">Norway</Option>
                                            <Option value="Poland">Poland</Option>
                                            <Option value="Portugal">Portugal</Option>
                                            <Option value="Romania">Romania</Option>
                                            <Option value="Russia">Russia</Option>
                                            <Option value="San Marino">San Marino</Option>
                                            <Option value="Serbia">Serbia</Option>
                                            <Option value="Slovakia">Slovakia</Option>
                                            <Option value="Slovenia">Slovenia</Option>
                                            <Option value="Spain">Spain</Option>
                                            <Option value="Sweden">Sweden</Option>
                                            <Option value="Switzerland">Switzerland</Option>
                                            <Option value="Turkey">Turkey</Option>
                                            <Option value="Ukraine">Ukraine</Option>
                                            <Option value="United Kingdom">United Kingdom</Option>
                                            <Option value="Vatican City">Vatican City</Option>
                                            <Option value="Australia">Australia</Option>
                                            <Option value="New Zealand">New Zealand</Option>
                                        </Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="County"
										name="county"
										initialValue={addressObj.county}
									>
										<Input onChange={changeIptHandle('county')} value={addressObj.county} />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Full Name"
										name="fullname"
										initialValue={addressObj.uname}
									>
										<Input onChange={changeIptHandle('uname')} value={addressObj.uname} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="City"
										name="city"
										initialValue={addressObj.city}
									>
										<Input onChange={changeIptHandle('city')} value={addressObj.city} />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Company"
										name="company"
										initialValue={addressObj.gs}
									>
										<Input onChange={changeIptHandle('gs')} value={addressObj.gs} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Address"
										name="address"
										initialValue={addressObj.address}
									>
										<Input onChange={changeIptHandle('address')} value={addressObj.address} />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										label="Postcode"
										name="postcode"
										initialValue={addressObj.postcode}
									>
										<Input onChange={changeIptHandle('postcode')} value={addressObj.postcode} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Phone Number"
										name="phoneNumber"
										initialValue={addressObj.phone}
									>
										<Input onChange={changeIptHandle('phone')} value={addressObj.phone} />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<div className="save" onClick={saveAddressHanle}>Save Changes</div>
							</Row>
						</Form>
						{/* <div className="two lis_item select">
											<div className="flex-center">
											{isBoxs?<div className="boxs" onClick={downBox}></div>:<div className="boxCli" onClick={changBox}></div>}

											<div className="subjectThree">Save as Default</div>
											</div>
										</div> */}

				</div>
					: ''
			}
			{addInfo && addInfo.loading2  && <Loader />}
			{messageInfo.msg ? <Message variant={messageInfo.variant}>{messageInfo.msg}</Message> : ''}
			{addInfo && addInfo.loading ? <div className="fullcreen"><LoadSpinner /></div>
				: <div>
					<div className="topName flex-center">
						<div className="welcome">Your Addresses</div>
					</div>
					<div className="detail">
						Click 'Add Address' to add a new address and click 'pencil button' to edit the selected address. Delivery options and delivery speeds may vary for different locations.
						</div>

					<div className="account_titleOne">Saved Addresses</div>
					<div className="flex-center boxUi">
						<div className="add flex" onClick={showAddress()}>
							<img src="./images/index/add1.png" className="addImg" />
							<div className="addContent">Add Address</div>
						</div>
						{
							addInfo.addresses && addInfo.addresses.map((item, index) => {
								return <div key="index" className={'boxUi_item ' + (item.status == 1 ? 'boxUi_item_default' : '')}>
									{
										item.status == 1 ? <div className="boxUi_item_header">
											<div>Default:</div>
											<img src="images/index/logo.png" />
										</div> : ''
									}

									<div className="boxUi_item_default_body ">
										<div className="flex-center" >
											<div className="boxName">{item.uname}</div>
											<div className="mar">●</div>
											<div>{item.phone}</div>
										</div>
										<div className="boxName names">{item.gs}</div>

										<div className="select_item">
											<div >{item.country}</div>
											<div>{item.county}</div>
											<div>{item.city}</div>
											<div>{item.address}</div>
											{/* <div>County</div>
													<div>Country</div> */}
										</div>
										<div className="flex-center modify">
											<Row gutter={10}>
												<Col>
													<Button
														type="primary"
														icon={ <EditOutlined />}
														onClick={showAddress(item)}
														style={{backgroundColor: '#c6d9ec', color: '#333'}}
													/>
												</Col>
												<Col>
													<Button
														type="primary"
														icon={ <DeleteOutlined />}
														onClick={deleteAddressHandle(item.id)}
														style={{backgroundColor: '#c6d9ec', color: '#333'}}
													/>
												</Col>
											</Row>
											{/*<img src="images/index/errs.png" className="errs" onClick={deleteAddressHandle(item.id)} />*/}
											{/*<img src="images/index/bi.png" className="bi" onClick={showAddress(item)} />*/}
										</div>
										{
											item.status != 1 ?<div onClick={setDefaultHandle(item.id)} className="default flex">Default</div>:''
										}

									</div>
								</div>

							})
						}
					</div>

				</div>
			}


		</div >
	)
}

export default AddressScreen
