import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadSpinner from '../components/LoadSpinner';
import Message from '../components/Message';
import SelectItem from '../components/SelectItem';
import '../css/HomeScreen.css';
import 'swiper/swiper-bundle.min.css';
import { listProducts } from '../actions/productActions';
import { Link } from 'react-router-dom';
import {Flex, Select} from "antd";
import {EyeOutlined, ShoppingCartOutlined} from "@ant-design/icons";

function StoreScreen({match }) {
    const dispatch = useDispatch();
    const id = match.params.id;
    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;
    const productList = useSelector(state => state.productList);
    const messageInfo = useSelector(state => state.message);
    const imgs = {
        3: './images/index/carui3.png',
        4: './images/index/carui2.png',
        5: './images/index/carui6.png',
        6: './images/index/carui3.png',
        7: './images/index/carui4.png',
        8: './images/index/carui5.png',
        9: './images/index/carui7.png',
        20: './images/index/carui8.png',
        25: './images/index/banneraroma.jpg',
        26: './images/index/banneraroma.jpg',
        12: './images/index/carui12.png'
    };
    const { loading, products, title } = productList;
    const [nowItem, setNowItem] = useState(null);
    const [show, setShow] = useState(false);
    const [sortBy, setSortBy] = useState('name_asc'); // Default sorting order is by name A to Z

    useEffect(() => {
        dispatch(listProducts('',true,id));
    }, [dispatch, id]);

    // Define sorted products based on the current sort order
    let sortedProducts = [...products];

    if (sortBy === 'name_asc') {
        sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === 'name_desc') {
        sortedProducts.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sortBy === 'price_asc') {
        sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortBy === 'price_desc') {
        sortedProducts.sort((a, b) => b.price - a.price);
    }

    const selectAddCart = (item, index) => {
        return () => {
            setNowItem(item);
            setShow(true);
        }
    };

    const noFun = () => {
        setNowItem({});
        setShow(false);
    };

    const handleSortChange = (value) => {
        setSortBy(value);
    };

    return (
        <div className="home">
            {show ? <SelectItem item={nowItem} noFun={noFun} /> : ''}

            {messageInfo.msg ? <Message variant={messageInfo.variant}>{messageInfo.msg}</Message> : ''}
            {loading ? <div className="fullcreen"><LoadSpinner /></div>
                : messageInfo.msg ? ''
                    :
                    <div>

                        <div className="maxWidth">
                            <div>
                                <div>
                                    <div>


                                        {/* Display products sorted by current sort order */}
                                        <div className="imgs" style={{height: '3.7rem',background:' url('+imgs[id]+')', marginBottom: '0.6rem'}}></div>
                                        {/* Added sorting UI */}
                                        <div className="ul-title-sort" style={{margin: '0.4rem 0.5rem 0.5rem 0rem'}}>
                                            <div className="sort-label">Sort by:</div>
                                            <Select
                                              value={sortBy}
                                              style={{ width: 160 }}
                                              onChange={handleSortChange}
                                              allowClear
                                              options={[
                                                  { value: 'name_asc', label: 'Name A to Z' },
                                                  { value: 'name_desc', label: 'Name Z to A' },
                                                  { value: 'price_asc', label: 'Price Low to High' },
                                                  { value: 'price_desc', label: 'Price High to Low' },
                                              ]}
                                            />
                                        </div>
                                        <div className="uls flex-center">
                                            {sortedProducts.map((item, index) => {
                                                return (
                                                    <div className="ulBox" key={index}>
                                                        <Link to={'/product/' + item.id}><img src={item.image} /></Link>
                                                        <div className="money p-title" style={{'justify-content': 'center'}}>{item.name}</div>
                                                        <Flex align="center" justify="space-between">
                                                            <div className="money">
                                                                {item.discount !== 1 ? <div><del>£{(item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</del><span style={{color: '#FFC008','marginLeft': '0.2rem'}}>£{(item.discount * item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</span></div> : <span style={{color: '#FFC008','marginLeft': '0.2rem'}}>£{(item.pricemember[userInfo&&userInfo.name || 'A' ]).toFixed(2)}</span>}
                                                                {userInfo && userInfo.name && userInfo.name !== 'A' ? <img src="./images/index/novip.jpg" height="50px" style={{alignSelf: 'flex-start'}} />: ''}
                                                            </div>
                                                            <div className="swiper-btn-wrap flex-center">
                                                                <Link to={`/product/${item.id}`} className="btnLeft flex"><EyeOutlined /></Link>
                                                                <div className="flex btnRight"
                                                                     onClick={selectAddCart(item)}
                                                                >
                                                                    <ShoppingCartOutlined />
                                                                </div>
                                                            </div>
                                                        </Flex>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div >
    )


}

export default StoreScreen
